// stores/courseStats.js
import { defineStore } from "pinia";
import axios from "axios";

export const useUserStore = defineStore("user", {
  state: () => ({
    user: {},
  }),
  getters: {
    getUser(state) {
      return state.user;
    },
  },
  actions: {
    async fetchUser() {
      try {
        const data = await axios.get("/api/users/user/me");
        if (data.data.name) {
          if (!data.data.imageUrl || data.data.imageUrl === "")
            data.data.imageUrl = "images/profile.svg";
          this.user = data.data;
        } else {
          this.user = {};
        }
      } catch (error) {
        console.log(error);
        this.user = {};
      }
    },
  },
});
