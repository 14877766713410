<script setup>
import { computed } from "vue";
import {
  PresentationChartLineIcon,
  TrashIcon,
  AdjustmentsHorizontalIcon,
  QuestionMarkCircleIcon,
  CheckBadgeIcon,
  EyeIcon,
  CloudArrowDownIcon,
} from "@heroicons/vue/20/solid";

import { useDateFormat } from "@vueuse/core";
import { useRouter } from "vue-router";
import { useBreadCrumbStore } from "../stores/breadCrumbs.js";
import SocialShare from "./SocialShare.vue";

const emit = defineEmits(["openQuiz", "delete"]);

const breadCrumbsStore = useBreadCrumbStore();
const props = defineProps(["course", "user"]);
const router = useRouter();
const formatted = useDateFormat(props.course.createdAt, "H:mm D-M-YYYY");

// course.type
const fileIcon = computed(() => {
  if (props.course.type.includes("presentation")) {
    return PresentationChartLineIcon;
  } else {
  }
  return QuestionMarkCircleIcon;
});
//chose image based on course status
const imageURL = computed(() => {
  if (props.course.status === "COMPLETED") {
    return `https://content.scormr.com/${props.course.job_id}/data/Thumbnails/Slide1.jpg`;
  } else {
    return "/images/placeholder.svg";
  }
});

const shareURL = computed(() => {
  if (window.location.hostname === "localhost") {
    return `http://localhost:3000/share/${props.course.job_id}`;
  } else {
    return `https://scormr.com/share/${props.course.job_id}`;
  }
});

const handleCourseClick = (course) => {
  breadCrumbsStore.addCrumb({ name: course.name, href: "#", current: true });
  router.push(`/course/enrollments/${course.id}`);
};

const handleDownloadClick = () => {
  const phData = { job_id: props.course.job_id };
  window.posthog.capture("downloadCourse", phData);
};

//delete course
const handleDeleteClick = () => {
  emit("delete", props.course);
  const phData = { job_id: props.course.job_id };
  window.posthog.capture("deletedCourse", phData);
};
//delete course
const handleQuizClick = () => {
  console.log("handleQuizClick");
  emit("openQuiz", props.course);
};
</script>

<template>
  <div class="flex items-center flex-wrap px-4 py-4 sm:px-6">
    <div class="flex flex-wrap min-w-0 flex-1 items-center">
      <div class="flex-shrink-0">
        <img class="h-12 w-12 rounded-md" :src="imageURL" alt="" />
      </div>
      <div class="min-w-0 flex-1 px-4 md:grid md:grid-cols-2 md:gap-4">
        <div>
          <p class="truncate font-medium text-gray-100">
            {{ course.name }}
          </p>
          <p class="text-sm text-gray-500">
            Created at:
            <time :datetime="course.createdAt">{{ formatted }}</time>
          </p>
        </div>

        <div class="hidden md:block items-center">
          <p
            class="text-xs inline-flex items-center font-bold leading-sm px-3 py-1 bg-gray-300 text-gray-700 rounded-full"
          >
            <Component class="w-5 h-5" aria-hidden="true" :is="fileIcon" />
          </p>
        </div>
      </div>
    </div>
    <div
      class="flex flex-row flex-wrap gap-1"
      v-if="course.status === 'COMPLETED'"
    >
      <div class="tooltip tooltip-primary" data-tip="Preview">
        <a
          target="_blank"
          rel="noreferrer"
          :href="shareURL"
          role="button"
          class="inline-flex items-center rounded-md border border-transparent bg-gray-500 px-4 py-2 shadow-sm text-gray-400 hover:text-gray-800 hover:bg-gray-100 focus:outline-none focus:ring-2 focus:ring-grey-500 focus:ring-offset-2"
        >
          <EyeIcon class="w-5 h-5" aria-hidden="true" />
        </a>
      </div>
      <div class="tooltip tooltip-primary" data-tip="Download">
        <a
          @click="handleDownloadClick"
          :href="`/api/courses/download/${course.job_id}`"
          class="inline-flex items-center rounded-md border border-transparent bg-gray-500 px-4 py-2 shadow-sm text-gray-400 hover:text-gray-800 hover:bg-gray-100 focus:outline-none focus:ring-2 focus:ring-grey-500 focus:ring-offset-2"
        >
          <CloudArrowDownIcon class="w-5 h-5" aria-hidden="true" />
        </a>
      </div>
      <div
        class="tooltip tooltip-primary"
        data-tip="Edit Player"
        v-if="user.beta"
      >
        <button
          @click="handleCourseClick(course)"
          class="inline-flex items-center rounded-md border border-transparent bg-gray-500 px-4 py-2 shadow-sm text-gray-400 hover:text-gray-800 hover:bg-gray-100 focus:outline-none focus:ring-2 focus:ring-grey-500 focus:ring-offset-2"
        >
          <AdjustmentsHorizontalIcon class="w-5 h-5" aria-hidden="true" />
        </button>
      </div>

      <div class="tooltip tooltip-primary" data-tip="Add/Edit Quiz">
        <button
          @click="handleQuizClick()"
          class="inline-flex items-center rounded-md border border-transparent bg-gray-500 px-4 py-2 shadow-sm text-gray-400 hover:text-gray-800 hover:bg-gray-100 focus:outline-none focus:ring-2 focus:ring-grey-500 focus:ring-offset-2"
        >
          <CheckBadgeIcon class="w-5 h-5" aria-hidden="true" />
        </button>
      </div>
      <div class="tooltip tooltip-error" data-tip="Delete">
        <button
          @click="handleDeleteClick()"
          class="inline-flex items-center rounded-md border border-transparent bg-gray-500 px-4 py-2 shadow-sm text-gray-400 hover:text-red-800 hover:bg-gray-100 focus:outline-none focus:ring-2 focus:ring-grey-500 focus:ring-offset-2"
        >
          <TrashIcon class="w-5 h-5" aria-hidden="true" />
        </button>
      </div>
      <SocialShare :course="course" />
    </div>
    <div class="flex gap-1" v-if="course.status !== 'COMPLETED'">
      <div class="flex flex-col gap-1 text-gray-100">
        <progress class="progress progress-secondary w-56"></progress>
        <span>Processing</span>
      </div>

      <div class="tooltip tooltip-error" data-tip="Delete">
        <button
          @click="handleDeleteClick()"
          class="inline-flex items-center rounded-md border border-transparent bg-gray-500 px-4 py-2 shadow-sm text-gray-400 hover:text-red-800 hover:bg-gray-100 focus:outline-none focus:ring-2 focus:ring-grey-500 focus:ring-offset-2"
        >
          <TrashIcon class="w-5 h-5" aria-hidden="true" />
        </button>
      </div>
    </div>
  </div>
</template>
