<script setup>
import { ref } from "vue";
import axios from "axios";
//import aws from "aws-sdk";
//import "../../public/js/aws-sdk.min.js";
import vueFilePond from "vue-filepond";
import FilePondPluginFileValidateType from "filepond-plugin-file-validate-type";
import "filepond/dist/filepond.min.css";
import { CheckCircleIcon, XMarkIcon } from "@heroicons/vue/20/solid";
import { useUserStore } from "../stores/user";
const emit = defineEmits(["courseCreated"]);

const userStore = useUserStore();
const myFiles = ref([]);
const uploadDone = ref(false);
const uploadErrors = ref(false);
const uploadCount = ref(0);
const pond = ref(null);
AWS.config.update({
  secretAccessKey: "tgs1NSp6lbtDmkjm4wLgtJGal+k+j2yaezkf913/",
  accessKeyId: "AKIA42227QX4KEIEMQ7Q",
});

const s3 = new AWS.S3({
  region: "us-east-1",
});

const createCourse = async (file, url) => {
  const newCourse = {
    url: url,
    name: file.name,
    type: file.type,
  };
  window.posthog.capture("createCourse", {
    newCourse,
  });
  const result = await axios.post("/api/courses", {
    course: newCourse,
  });
  emit("courseCreated");
  return result;
};

const singleUpload = async (file, folder, progress) => {
  const key =
    folder +
    "/" +
    userStore.user.id +
    "/" +
    file.name
      .normalize("NFD")
      .replace(/\p{Diacritic}/gu, "")
      .replace(/\s/g, "-");
  const params = {
    Bucket: "scormertest",
    Key: key,
    Expires: 10,
    ContentType: file.type,
  };
  const url = s3.getSignedUrl("putObject", params);
  try {
    const uploadResult = await axios.put(url, file, {
      headers: {
        "Content-Type": file.type,
      },
      onUploadProgress: (progressEvent) => {
        const { loaded, total } = progressEvent;
        progress(true, loaded, total);
      },
    });
    const bucketUrl = decodeURIComponent(
      uploadResult.request.responseURL
    ).split(key)[0];
    uploadResult.key = key;
    uploadResult.fullPath = bucketUrl + key;
    return uploadResult;
  } catch (err) {
    // TODO: error handling
    console.log(err);
    return err;
  }
};

const uploadFile = async (file, metadata, load, error, progress, abort) => {
  const result = await singleUpload(file, "testing", progress);
  if (result.status && result.status === 200) {
    uploadCount.value++;
    createCourse(file, result.fullPath);
    load(file); // Let FilePond know the processing is done
  } else {
    uploadErrors.value = true;
    error(); // Let FilePond know the upload was unsuccessful
  }
  return {
    abort: () => {
      // This function is entered if the user has tapped the cancel button
      // Let FilePond know the request has been cancelled
      abort();
    },
  };
};

const onRemoveFile = async (a, b) => {};

const handleFilesDone = (e, files) => {};
const handleUpdatefiles = (files) => {
  myFiles.value = files;
  uploadDone.value = false;
};
const handleProcessFile = (e, file) => {
  uploadDone.value = true;
};
const deleteObjectByKey = (key) => {
  const params = {
    Bucket: "scormertest",
    Key: key,
  };
  const data = s3.deleteObject(params).promise();

  return data;
};

const clearList = () => {
  pond.value.removeFiles();
  uploadDone.value = false;
  uploadErrors.value = false;
};
// Create component
const FilePond = vueFilePond(FilePondPluginFileValidateType);
</script>

<template>
  <div class="max-w-7xl mx-auto py-1 px-1 sm:px-6 lg:px-2">
    <div v-if="uploadDone" class="rounded-md bg-green-100 m-3 p-4">
      <div class="flex">
        <div class="flex-shrink-0">
          <CheckCircleIcon class="h-5 w-5 text-green-400" aria-hidden="true" />
        </div>
        <div class="ml-3">
          <p class="text-sm font-medium text-green-800">
            Successfully uploaded {{ uploadCount }} file<span
              v-if="uploadCount > 1"
              >s</span
            >! It will take a few minutes to process them.
          </p>
        </div>
        <div class="ml-auto pl-3">
          <div class="-mx-1.5 -my-1.5">
            <button
              @click="clearList"
              type="button"
              class="inline-flex rounded-md bg-green-100 p-1.5 text-green-500 hover:bg-green-200 focus:outline-none focus:ring-2 focus:ring-green-600 focus:ring-offset-2 focus:ring-offset-green-50"
            >
              <span class="sr-only">Dismiss</span>
              <XMarkIcon class="h-5 w-5" aria-hidden="true" />
            </button>
          </div>
        </div>
      </div>
    </div>
    <file-pond
      name="pond-uploader"
      ref="pond"
      :allow-revert="false"
      :drop-on-page="true"
      :drop-on-element="false"
      credits="false"
      label-idle="Drop files here to create a course... (or click here to browse)"
      v-bind:allow-multiple="true"
      accepted-file-types="application/vnd.ms-powerpoint, application/vnd.openxmlformats-officedocument.presentationml.presentation"
      :server="{
        process: (fieldName, file, metadata, load, error, progress, abort) => {
          uploadFile(file, metadata, load, error, progress, abort);
        },
      }"
      v-bind:files="myFiles"
      @processfiles="handleFilesDone"
      @updatefiles="handleUpdatefiles"
      @processfile="handleProcessFile"
      @removefile="onRemoveFile"
    />
  </div>
</template>

<style>
.filepond--drip-blob {
  background-color: white;
}
.filepond--drop-label {
  font-size: 1.5rem;
  color: rgb(217, 217, 217);
}
.filepond--root {
  color: white;
}
.filepond--panel-root {
  color: white;
  background-color: black;
}
.filepond--file {
  color: white;
  background-color: rgb(44, 44, 44);
}
</style>
