import { createApp } from "vue";
import { createPinia } from "pinia";

import piniaPluginPersistedstate from "pinia-plugin-persistedstate";
import VueSocialSharing from "vue-social-sharing";

/* import font awesome icon component */
import { FontAwesomeIcon } from "@fortawesome/vue-fontawesome";
import { library } from "@fortawesome/fontawesome-svg-core";
import { faCheckSquare, faCircleDot } from "@fortawesome/free-solid-svg-icons";
library.add(faCheckSquare, faCircleDot);
import "./styles/index.css";
import App from "./App.vue";
import router from "./router";

const pinia = createPinia();
pinia.use(piniaPluginPersistedstate);
const app = createApp(App).component("font-awesome-icon", FontAwesomeIcon);

app.use(router);
app.use(pinia);
app.use(VueSocialSharing);
app.mount("#app");
