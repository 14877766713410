<!-- This example requires Tailwind CSS v2.0+ -->
<template>
  <Menu as="div" class="relative inline-block text-left">
    <div class="tooltip tooltip-primary" data-tip="Share">
      <MenuButton
        class="rounded-md border border-transparent bg-gray-500 px-4 py-2 shadow-sm text-gray-400 hover:text-gray-800 hover:bg-gray-100 focus:outline-none focus:ring-2 focus:ring-grey-500 focus:ring-offset-2"
      >
        <ShareIcon class="h-5 w-5" aria-hidden="true" />
      </MenuButton>
    </div>

    <transition
      enter-active-class="transition ease-out duration-100"
      enter-from-class="transform opacity-0 scale-95"
      enter-to-class="transform opacity-100 scale-100"
      leave-active-class="transition ease-in duration-75"
      leave-from-class="transform opacity-100 scale-100"
      leave-to-class="transform opacity-0 scale-95"
    >
      <MenuItems
        class="absolute right-0 z-10 mt-2 w-56 origin-top-right divide-y divide-gray-100 rounded-md bg-white shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none"
      >
        <div class="py-1">
          <MenuItem v-slot="{ active }">
            <ShareNetwork
              @open="shareOpen"
              network="facebook"
              :url="`https://scormr.com/share/${course.job_id}`"
              :title="`${course.name}`"
              description="`Check out this cool ${course.name} course I created on scormr.com!`"
              :quote="`Check out this cool ${course.name} course I created on scormr.com!`"
              hashtags="elearning,training,powerpoint,pptx,scorm"
              :media="` https://scormr.com/content/${course.job_id}/data/Thumbnails/Slide1.jpg`"
            >
              <span
                class="group flex items-center gap-3 px-4 py-2 text-sm text-gray-500 hover:text-gray-700"
              >
                <font-awesome-icon icon="fa-brands fa-square-facebook" />
                Facebook
              </span>
            </ShareNetwork>
          </MenuItem>
          <MenuItem v-slot="{ active }">
            <ShareNetwork
              @open="shareOpen"
              network="twitter"
              :url="`https://scormr.com/share/${course.job_id}`"
              :title="`${course.name}`"
              description="`Check out this cool ${course.name} course I created on scormr.com!`"
              :quote="`Check out this cool ${course.name} course I created on scormr.com!`"
              hashtags="elearning,training,powerpoint,pptx,scorm"
              :media="` https://scormr.com/content/${course.job_id}/data/Thumbnails/Slide1.jpg`"
            >
              <span
                class="group flex items-center gap-3 px-4 py-2 text-sm text-gray-500 hover:text-gray-700"
              >
                <font-awesome-icon icon="fa-brands fa-square-twitter" />
                Twitter</span
              >
            </ShareNetwork>
          </MenuItem>
          <MenuItem v-slot="{ active }">
            <ShareNetwork
              @open="shareOpen"
              network="linkedin"
              :url="`https://scormr.com/share/${course.job_id}`"
              :title="`${course.name}`"
              description="`Check out this cool ${course.name} course I created on scormr.com!`"
              :quote="`Check out this cool ${course.name} course I created on scormr.com!`"
              hashtags="elearning,training,powerpoint,pptx,scorm"
              :media="` https://scormr.com/content/${course.job_id}/data/Thumbnails/Slide1.jpg`"
            >
              <span
                class="group flex items-center gap-3 px-4 py-2 text-sm text-gray-500 hover:text-gray-700"
              >
                <font-awesome-icon icon="fa-brands fa-linkedin " />
                LinkedIn</span
              >
            </ShareNetwork>
          </MenuItem>
          <MenuItem v-slot="{ active }">
            <ShareNetwork
              @open="shareOpen"
              network="email"
              :url="`https://scormr.com/share/${course.job_id}`"
              :title="`${course.name}`"
              description="`Check out this cool ${course.name} course I created on scormr.com!`"
              :quote="`Check out this cool ${course.name} course I created on scormr.com!`"
              hashtags="elearning,training,powerpoint,pptx,scorm"
              :media="` https://scormr.com/content/${course.job_id}/data/Thumbnails/Slide1.jpg`"
            >
              <span
                class="group flex items-center gap-3 px-4 py-2 text-sm text-gray-500 hover:text-gray-700"
              >
                <EnvelopeIcon class="h-4 w-4" aria-hidden="true" />
                Email</span
              >
            </ShareNetwork>
          </MenuItem>
        </div>
      </MenuItems>
    </transition>
  </Menu>
</template>

<script setup>
import { Menu, MenuButton, MenuItem, MenuItems } from "@headlessui/vue";
import { library } from "@fortawesome/fontawesome-svg-core";
import {
  faFacebookSquare,
  faTwitterSquare,
  faLinkedin,
} from "@fortawesome/free-brands-svg-icons";

import { ShareIcon, EnvelopeIcon, LinkIcon } from "@heroicons/vue/20/solid";

const props = defineProps(["course"]);

library.add(faTwitterSquare, faFacebookSquare, faLinkedin);

const shareOpen = (network, url) => {
  window.gtag("share", "share_course", {
    event_label: network,
  });
  const phData = { job_id: props.course.job_id, network: network };
  window.posthog.capture("shareCourse", phData);
};
</script>
