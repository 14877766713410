import { defineStore } from "pinia";

export const useBreadCrumbStore = defineStore("breadcrumbs", {
  state: () => {
    return {
      breadcrumbs: [],
    };
  },
  getters: {
    getCrumbs(state) {
      return state.breadcrumbs;
    },
  },
  actions: {
    addCrumb(page) {
      this.breadcrumbs.push(page);
      console.log("new breadcrumbs", this.breadcrumbs);
    },
    navUp(i) {
      this.breadcrumbs.splice(i, this.breadcrumbs.length);
    },
  },
  persist: true,
});
