// stores/courseStats.js
import { defineStore } from "pinia";
import axios from "axios";

export const useCourseListStore = defineStore("courseList", {
  state: () => ({
    courseList: [],
  }),
  getters: {
    getCourses(state) {
      return state.courseList;
    },
  },
  actions: {
    async deleteCourse(jid) {
      const data = await axios.delete("/api/courses/" + jid);
      if (data.data.success) {
        this.courseList = this.courseList.filter(
          (course) => course.job_id !== jid
        );
      }

      //console.log(newList);
    },
    async fetchCourses() {
      try {
        const data = await axios.get("/api/courses/mine");
        this.courseList = data.data.reverse();
      } catch (error) {
        console.log(error);
      }
    },
  },
});
