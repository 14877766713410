import { createRouter, createWebHistory } from "vue-router";
import Home from "./layouts/Home.vue";
import Login from "./layouts/Login.vue";
import Courses from "./layouts/Courses.vue";
import Team from "./layouts/Team.vue";
import Upload from "./layouts/Uploader.vue";

const router = createRouter({
  history: createWebHistory(),
  scrollBehavior: () => ({ top: 0 }),
  routes: [
    {
      path: "/",
      component: Home,
      name: "Home",
    },
    {
      path: "/login",
      component: Login,
      name: "Login",
    },
    {
      path: "/courses",
      component: Courses,
      name: "Courses",
    },
    {
      path: "/team",
      component: Team,
      name: "Team",
    },
    {
      path: "/upload",
      component: Upload,
      name: "Upload",
    },
  ],
});

router.afterEach((to) => {
  window.gtag("set", "page", to.path);
  window.gtag("event", "page_view", {
    page_title: to.name,
    page_path: to.path,
  });
});

export default router;
