<script setup>
import { computed, nextTick, onMounted, ref } from "vue";
import { MagnifyingGlassIcon } from "@heroicons/vue/20/solid";
import CourseListItem from "../components/CourseListItem.vue";
import { useCourseListStore } from "../stores/courseList";
import Uploader from "./Uploader.vue";
import QuizBuilderModal from "../components/QuizBuilderModal.vue";
import { useQuizStore } from "../stores/activeQuiz";
import { useUserStore } from "../stores/user";

const userStore = useUserStore();
const quizStore = useQuizStore();

const searchTerm = ref("");
const showQuiz = ref(false);
const activeCourse = ref({});
const courseCheckCount = ref(0);

const courseListStore = useCourseListStore();
const filteredCourseList = computed(() => {
  const rgx = new RegExp(searchTerm.value, "i");
  return courseListStore.courseList.filter((item) => item.name.match(rgx));
});

onMounted(() => {
  courseListStore.fetchCourses();
  checkCompleted();
});

const allComplete = computed(() => {
  let complete = true;
  courseListStore.courseList.forEach((item) => {
    if (item.status !== "COMPLETED") {
      complete = false;
    }
  });
  return complete;
});

const checkCompleted = async () => {
  await nextTick();

  if (
    (!allComplete.value || courseListStore.courseList.length === 0) &&
    courseCheckCount.value < 30
  ) {
    courseCheckCount.value++;
    courseListStore.fetchCourses();
    setTimeout(() => {
      checkCompleted();
    }, 8000);
  } else {
  }
};

const openQuiz = (course) => {
  console.log("open quiz", course);
  activeCourse.value = course;
  showQuiz.value = true;
};

const closeQuizModal = () => {
  showQuiz.value = false;
};

// delete quiz
const deleteQuiz = () => {
  quizStore.deleteQuiz();
  activeCourse.value.hasQuiz = false;
  showQuiz.value = false;
};

// delete Course
const deleteCourse = (course) => {
  courseListStore.deleteCourse(course.job_id);
};

const createQuiz = () => {
  showQuiz.value = false;
  activeCourse.value.hasQuiz = true;
  window.posthog.capture("createQuiz", {
    course: activeCourse.value.name,
    job_id: activeCourse.value.job_id,
  });
  //post quiz data to server
  quizStore.postQuiz();
};

const courseAdded = async () => {
  courseListStore.fetchCourses();
  //reset counter
  courseCheckCount.value = 0;
  await nextTick();
  setTimeout(() => {
    checkCompleted();
  }, 1000);
};
</script>

<template>
  <Uploader @courseCreated="courseAdded()" />
  <div class="flex flex-1 items-center justify-center px-2 lg:ml-6">
    <div class="w-full max-w-lg lg:max-w-xs">
      <label for="search" class="sr-only">Search</label>
      <div class="relative">
        <div
          class="pointer-events-none absolute inset-y-0 left-0 flex items-center pl-3"
        >
          <MagnifyingGlassIcon
            class="h-5 w-5 text-gray-400"
            aria-hidden="true"
          />
        </div>
        <input
          id="search"
          name="search"
          v-model="searchTerm"
          class="block w-full text-gray-100 rounded-md border border-gray-400 bg-gray-900 py-2 pl-10 pr-3 leading-5 placeholder-gray-300 focus:border-gray-500 focus:placeholder-gray-400 focus:outline-none focus:ring-1 focus:ring-gray-100 sm:text-sm"
          placeholder="Search"
          type="search"
        />
      </div>
    </div>
  </div>
  <div class="mt-3 bg-black shadow rounded-md mb-5">
    <TransitionGroup name="list" tag="ul" class="divide-y divide-gray-800">
      <li v-for="course in filteredCourseList" :key="course.id">
        <CourseListItem
          @openQuiz="openQuiz(course)"
          @delete="deleteCourse(course)"
          :course="course"
          :user="userStore.user"
          class="rounded-sm bg-black"
        />
      </li>
    </TransitionGroup>
  </div>
  <QuizBuilderModal
    @closedModal="closeQuizModal()"
    :course="activeCourse"
    :open="showQuiz"
    @createQuiz="createQuiz()"
    @updatedQuiz="createQuiz()"
    @deleteQuiz="deleteQuiz()"
  />
</template>

<style scoped>
.list-move, /* apply transition to moving elements */
.list-enter-active,
.list-leave-active {
  transition: all 0.5s ease;
}

.list-enter-from,
.list-leave-to {
  opacity: 0;
  transform: translateX(30px);
}

/* ensure leaving items are taken out of layout flow so that moving
   animations can be calculated correctly. */
.list-leave-active {
  position: absolute;
}
</style>
