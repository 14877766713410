<template>
  <div class="px-1 mt-4">
    {{ sortKey }} / {{ sortDirection }}
    <div class="sm:flex sm:items-center">
      <div class="sm:flex-auto">
        <h1 class="text-xl font-semibold text-Passed-900">Members:</h1>
      </div>
      <div class="mt-4 sm:mt-0 sm:ml-16 sm:flex-none">
        <button
          @click=""
          type="button"
          class="inline-flex items-center justify-center rounded-md border border-transparent bg-indigo-600 px-4 py-2 text-sm font-medium text-white shadow-sm hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2 sm:w-auto"
        >
          Add Learner
        </button>
      </div>
    </div>
    <div class="mt-8 flex flex-col">
      <div class="-my-2 -mx-4 overflow-x-auto sm:-mx-6 lg:-mx-8">
        <div class="inline-block min-w-full py-2 align-middle md:px-6 lg:px-8">
          <div
            class="overflow-hidden shadow ring-1 ring-black ring-opacity-5 md:rounded-lg"
          >
            <table class="min-w-full divide-y divide-gray-300">
              <thead class="bg-gray-50">
                <tr>
                  <th scope="col" class="relative w-12 px-6 sm:w-16 sm:px-8">
                    <input
                      type="checkbox"
                      class="absolute left-4 top-1/2 -mt-2 h-4 w-4 rounded border-gray-300 text-indigo-600 focus:ring-indigo-500 sm:left-6"
                      :checked="
                        indeterminate ||
                        selectedPeople.length === members.length
                      "
                      :indeterminate="indeterminate"
                      @change="
                        selectedPeople = $event.target.checked
                          ? members.map((p) => p.id)
                          : []
                      "
                    />
                  </th>
                  <th
                    scope="col"
                    class="py-3.5 pl-4 pr-3 text-left text-sm font-semibold text-gray-900 sm:pl-6"
                  >
                    Name
                  </th>

                  <th
                    scope="col"
                    class="px-3 py-3.5 text-left text-sm font-semibold text-gray-900"
                  >
                    Roles
                  </th>
                  <th
                    scope="col"
                    class="px-3 py-3.5 text-left text-sm font-bold text-gray-900 border-b-4 border-indigo-500"
                  >
                    Latest Access
                  </th>
                  <th scope="col" class="relative py-3.5 pl-3 pr-4 sm:pr-6">
                    <span class="sr-only">Edit</span>
                  </th>
                </tr>
              </thead>
              <tbody class="divide-y divide-gray-200 bg-white">
                <tr
                  v-for="member in sortedMembers"
                  :key="member.id"
                  :class="[selectedPeople.includes(member.id) && 'bg-gray-50']"
                >
                  <td class="relative w-12 px-6 sm:w-16 sm:px-8">
                    <div
                      v-if="selectedPeople.includes(member.id)"
                      class="absolute inset-y-0 left-0 w-0.5 bg-indigo-600"
                    ></div>
                    <input
                      type="checkbox"
                      class="absolute left-4 top-1/2 -mt-2 h-4 w-4 rounded border-gray-300 text-indigo-600 focus:ring-indigo-500 sm:left-6"
                      :value="member.id"
                      v-model="selectedPeople"
                    />
                  </td>
                  <td class="whitespace-nowrap py-4 pl-4 pr-3 text-sm sm:pl-6">
                    <div class="flex items-center">
                      <div class="h-10 w-10 flex-shrink-0">
                        <img
                          class="h-10 w-10 rounded-full"
                          :src="member.image"
                          alt=""
                        />
                      </div>
                      <div class="ml-4">
                        <div class="font-medium text-gray-900">
                          {{ member.name }}
                        </div>
                        <div class="text-gray-500">{{ member.email }}</div>
                      </div>
                    </div>
                  </td>

                  <td class="whitespace-nowrap px-3 py-4 text-sm text-gray-500">
                    <span
                      v-for="role in member.roles"
                      :key="role"
                      :class="getClass(role.title)"
                      >{{ role.title }}</span
                    >
                  </td>
                  <td class="whitespace-nowrap px-3 py-4 text-sm text-gray-900">
                    {{ member.lastAccessed }}
                  </td>

                  <td
                    class="relative whitespace-nowrap py-4 pl-3 pr-4 text-right text-sm font-medium sm:pr-6"
                  >
                    <a href="#" class="text-indigo-600 hover:text-indigo-900"
                      >Edit<span class="sr-only">, {{ member.name }}</span></a
                    >
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script setup>
import { ref, computed, reactive } from "vue";
const selectedPeople = ref([]);
const members = ref([]);

const props = defineProps(["members"]);
members.value = props.members;
console.log("members", members.value);
const sortKey = ref("name");
const sortDirection = ref(-1);
const checked = ref(false);
const indeterminate = computed(() => {
  selectedPeople.value.length > 0 &&
    selectedPeople.value.length < members.value.length;
});

const sortedMembers = computed(() => {
  let itemsClone = [...props.members]; // Change added
  const sortedArray = itemsClone.sort((a, b) => {
    if (sortDirection.value === 1) {
      if (a[sortKey.value] < b[sortKey.value]) return -1;
      if (a[sortKey.value] > b[sortKey.value]) return 1;
      return 0;
    } else {
      if (b[sortKey.value] < a[sortKey.value]) return -1;
      if (b[sortKey.value] > a[sortKey.value]) return 1;
      return 0;
    }
  });
  return sortedArray;
});

function sortBy(key) {
  sortKey.value = key;
  sortDirection.value *= -1;
}
function getClass(role) {
  console.log("role", role);
  const css =
    " inline-flex rounded-full px-2 mx-1 text-xs font-semibold leading-5";
  let color = "";
  switch (role) {
    case "Admin":
      color = "text-cyan-900 bg-cyan-100 ";
      break;
    case "Manager":
      color = "text-indigo-900 bg-indigo-100 ";
      break;
    case "Learner":
      color = "text-fuchsia-900 bg-fuchsia-100 ";
      break;
    case "Enrolled":
      color = "text-Enrolled-900 bg-Enrolled-100 ";
      break;
    case "Failed":
      color = "text-Failed-900 bg-Failed-100 ";
      break;
  }
  return color + css;
}
</script>
