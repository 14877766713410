<script setup>
import { onMounted, ref } from "vue";
import contenteditable from "vue-contenteditable";
import short from "short-uuid";
import { useQuizStore } from "../stores/activeQuiz";
const props = defineProps(["course"]);
const quizStore = useQuizStore();
const currentQuestion = ref(0);
const quizJson = ref({
  course: props.course.job_id,
  questions: [
    {
      stem: "Edit question title here.",
      responses: [
        {
          text: "Option 1",
          id: short.generate(),
        },
        {
          text: "Option 2",
          id: short.generate(),
        },
      ],
      correct: [],
      type: "radio",
      id: short.generate(),
    },
  ],
  showFeedback: true,
  passingScore: 90,
});
const active = ref(false);
const quizLoaded = ref(false);

onMounted(() => {
  getQuiz();
});

const getQuiz = async () => {
  await quizStore.fetchQuiz(props.course.job_id);
  console.log("qb store:", quizStore.quiz);
  if (quizStore.quiz.noQuiz) {
    console.log("is empty");
    quizStore.quiz = quizJson;
  }
  quizLoaded.value = true;
};

const checkPassingScoreInput = () => {
  if (quizStore.quiz.passingScore > 100) {
    quizStore.quiz.passingScore = 100;
  }
};

const updatedCorrectResponses = (event) => {
  console.log(event.target.checked, event.target.id);
  if (quizStore.quiz.questions[currentQuestion.value].type === "radio") {
    quizStore.quiz.questions[currentQuestion.value].correct = [event.target.id];
  } else {
    if (event.target.checked) {
      quizStore.quiz.questions[currentQuestion.value].correct.push(
        event.target.id
      );
    } else {
      const newArr = quizStore.quiz.questions[
        currentQuestion.value
      ].correct.filter((response) => {
        return response !== event.target.id;
      });
      quizStore.quiz.questions[currentQuestion.value].correct = newArr;
    }
  }
};

const addQuestion = () => {
  quizStore.quiz.questions.push(createNewQuestion());
  nextQuestion();
  console.log(quizStore.quiz.questions);
};

const createNewQuestion = () => {
  const newQ = {
    stem: "Edit question title here.",
    responses: [
      {
        text: "Option 1",
        id: short.generate(),
      },
      {
        text: "Option 2",
        id: short.generate(),
      },
    ],
    correct: [],
    type: "radio",
    id: short.generate(),
  };
  return newQ;
};

const prevQuestion = () => {
  active.value = false;
  currentQuestion.value--;
  setTimeout(() => (active.value = true), 500);
  //;
};
const nextQuestion = () => {
  active.value = false;
  currentQuestion.value++;
  setTimeout(() => (active.value = true), 500);
  //;
};
const addResponse = () => {
  quizStore.quiz.questions[currentQuestion.value].responses.push({
    text: `Option ${
      quizStore.quiz.questions[currentQuestion.value].responses.length + 1
    }.`,
    id: short.generate(),
  });
};

const clearStem = () => {
  if (
    quizStore.quiz.questions[currentQuestion.value].stem ===
    "Edit question title here."
  )
    quizStore.quiz.questions[currentQuestion.value].stem = "";
};

const clearOption = (response) => {
  if (response.text.match(/Option \d/)) response.text = "";
};

const removeResponse = (response) => {
  quizStore.quiz.questions[currentQuestion.value].responses =
    quizStore.quiz.questions[currentQuestion.value].responses.filter(function (
      obj
    ) {
      return obj.id !== response.id;
    });
};
const removeQuestion = () => {
  quizStore.quiz.questions.splice(currentQuestion.value, 1);
  currentQuestion.value--;
};
</script>

<style scoped>
[contenteditable="true"]:active,
[contenteditable="true"]:focus {
  border: none;
  outline: 1px dashed rgb(192, 192, 192);
}
[contenteditable="true"]:hover,
[contenteditable="true"]:focus {
  border: none;
  outline: 1px dashed rgb(161, 161, 161);
}
</style>

<template>
  <div class="grid grid-cols-3 gap-2 min-h-full" v-if="quizLoaded">
    <div class="space-y-6 border bg-gray-50 w-full col-span-2">
      <div class="space-y-6 space-x-4">
        <legend class="contents text-base font-medium text-gray-900">
          Question {{ currentQuestion + 1 }} of
          {{ quizStore.quiz.questions.length }}
        </legend>
        <contenteditable
          tag="div"
          v-model="quizStore.quiz.questions[currentQuestion].stem"
          @click="clearStem"
        />
        <div class="mt-1">
          <div
            class="flex items-center"
            v-for="response in quizStore.quiz.questions[currentQuestion]
              .responses"
            :key="response.id"
          >
            <input
              :name="quizStore.quiz.questions[currentQuestion].id"
              :type="quizStore.quiz.questions[currentQuestion].type"
              @change="updatedCorrectResponses"
              :id="response.id"
              class="h-4 w-4 border-gray-300 text-gray-600 focus:ring-gray-500 mx-1"
            />
            <contenteditable
              tag="label"
              class="form-check-label min-w-[50%]"
              v-model="response.text"
              @click.stop="clearOption(response)"
            />
            <span
              class="ms-1 text-muted"
              v-show="
                quizStore.quiz.questions[currentQuestion].responses.length > 1
              "
              @click="removeResponse(response)"
              ><svg
                xmlns="http://www.w3.org/2000/svg"
                width="16"
                height="16"
                fill="currentColor"
                class="bi bi-trash"
                viewBox="0 0 16 16"
              >
                <path
                  d="M5.5 5.5A.5.5 0 0 1 6 6v6a.5.5 0 0 1-1 0V6a.5.5 0 0 1 .5-.5zm2.5 0a.5.5 0 0 1 .5.5v6a.5.5 0 0 1-1 0V6a.5.5 0 0 1 .5-.5zm3 .5a.5.5 0 0 0-1 0v6a.5.5 0 0 0 1 0V6z"
                />
                <path
                  fill-rule="evenodd"
                  d="M14.5 3a1 1 0 0 1-1 1H13v9a2 2 0 0 1-2 2H5a2 2 0 0 1-2-2V4h-.5a1 1 0 0 1-1-1V2a1 1 0 0 1 1-1H6a1 1 0 0 1 1-1h2a1 1 0 0 1 1 1h3.5a1 1 0 0 1 1 1v1zM4.118 4 4 4.059V13a1 1 0 0 0 1 1h6a1 1 0 0 0 1-1V4.059L11.882 4H4.118zM2.5 3V2h11v1h-11z"
                />
              </svg>
            </span>
          </div>
          <span class="text-success" @click="addResponse()"
            ><svg
              xmlns="http://www.w3.org/2000/svg"
              width="16"
              height="16"
              fill="currentColor"
              class="bi bi-plus-square"
              viewBox="0 0 16 16"
            >
              <path
                d="M14 1a1 1 0 0 1 1 1v12a1 1 0 0 1-1 1H2a1 1 0 0 1-1-1V2a1 1 0 0 1 1-1h12zM2 0a2 2 0 0 0-2 2v12a2 2 0 0 0 2 2h12a2 2 0 0 0 2-2V2a2 2 0 0 0-2-2H2z"
              />
              <path
                d="M8 4a.5.5 0 0 1 .5.5v3h3a.5.5 0 0 1 0 1h-3v3a.5.5 0 0 1-1 0v-3h-3a.5.5 0 0 1 0-1h3v-3A.5.5 0 0 1 8 4z"
              />
            </svg>
          </span>
        </div>
        <div class="grid md:grid-cols-2">
          <div>
            <input
              type="radio"
              id="qtype-multi"
              name="qtype"
              value="checkbox"
              class="hidden peer"
              required
              v-model="quizStore.quiz.questions[currentQuestion].type"
            />
            <label
              for="qtype-multi"
              class="inline-flex justify-between gap-1 items-center p-1 w-full text-gray-500 bg-white rounded-l-lg border border-gray-200 cursor-pointer dark:hover:text-gray-300 dark:border-gray-700 dark:peer-checked:text-blue-500 peer-checked:border-blue-600 peer-checked:text-blue-600 hover:text-gray-600 hover:bg-gray-100 dark:text-gray-400 dark:bg-gray-800 dark:hover:bg-gray-700"
            >
              <font-awesome-icon icon="fa-solid fa-square-check" />
              <div class="w-full">Multiple</div>
            </label>
          </div>
          <div>
            <input
              type="radio"
              id="qtype-single"
              name="qtype"
              value="radio"
              class="hidden peer"
              v-model="quizStore.quiz.questions[currentQuestion].type"
            />
            <label
              for="qtype-single"
              class="inline-flex justify-between gap-1 items-center p-1 w-full text-gray-500 bg-white rounded-r-lg border border-gray-200 cursor-pointer dark:hover:text-gray-300 dark:border-gray-700 dark:peer-checked:text-blue-500 peer-checked:border-blue-600 peer-checked:text-blue-600 hover:text-gray-600 hover:bg-gray-100 dark:text-gray-400 dark:bg-gray-800 dark:hover:bg-gray-700"
            >
              <font-awesome-icon icon="fa-solid fa-circle-dot" />
              <div class="w-full">Single</div>
            </label>
          </div>
        </div>
      </div>
      <div class="flex">
        <div class="flex-1">
          <button
            type="button"
            @click="prevQuestion()"
            class="mt-3 inline-flex w-full justify-center rounded-md border border-gray-300 bg-white px-4 py-2 text-base font-medium text-gray-700 shadow-sm disabled:bg-gray-200 hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2 sm:mt-0 sm:w-auto sm:text-sm"
            :disabled="currentQuestion === 0"
          >
            Back
          </button>
        </div>
        <div class="flex-auto">
          <button
            :disabled="quizStore.quiz.questions.length === 1"
            type="button"
            class="mt-3 inline-flex w-full justify-center rounded-md border border-gray-300 bg-white px-4 py-2 text-base font-medium text-gray-700 shadow-sm disabled:bg-gray-200 hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2 sm:mt-0 sm:w-auto sm:text-sm"
            @click="removeQuestion()"
          >
            Delete
          </button>
          <button
            type="button"
            @click="addQuestion()"
            class="mt-3 inline-flex w-full justify-center rounded-md border border-gray-300 bg-white px-4 py-2 text-base font-medium text-gray-700 shadow-sm disabled:bg-gray-200 hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2 sm:mt-0 sm:w-auto sm:text-sm"
            :disabled="currentQuestion + 1 < quizStore.quiz.questions.length"
          >
            Add
          </button>
        </div>
        <div class="">
          <button
            type="button"
            @click="nextQuestion()"
            class="mt-3 inline-flex w-full justify-center rounded-md border border-gray-300 bg-white px-4 py-2 text-base font-medium text-gray-700 shadow-sm disabled:bg-gray-200 hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2 sm:mt-0 sm:w-auto sm:text-sm"
            :disabled="currentQuestion + 1 === quizStore.quiz.questions.length"
          >
            Next
          </button>
        </div>
      </div>
    </div>
    <div class="space-y-2 border">
      <div class="card-body d-flex flex-column">
        <legend class="contents text-base font-medium text-gray-900">
          Quiz Settings
        </legend>
        <fieldset class="space-y-5">
          <div class="relative flex items-start">
            <div class="flex h-5 items-center">
              <input
                aria-describedby="feedback-description"
                name="showFeedbackCheck"
                type="checkbox"
                class="h-4 w-4 rounded border-gray-300 text-gray-600 focus:ring-gray-500"
                v-model="quizStore.quiz.showFeedback"
                id="showFeedbackCheck"
              />
            </div>
            <div class="ml-3">
              <label for="showFeedbackCheck" class="font-medium text-gray-700"
                >Show Feedback</label
              >
              <p id="feedback-description" class="text-gray-500">
                Notifiy the learner if they responded correctly.
              </p>
            </div>
          </div>
        </fieldset>

        <div class="col-3 pe-1">
          <input
            type="number"
            min="0"
            max="100"
            class="block rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:max-w-xs sm:text-sm"
            id="passingScoreInput"
            v-model="quizStore.quiz.passingScore"
            @change="checkPassingScoreInput"
          />
        </div>

        <label
          for="passingScoreRange"
          class="block text-sm font-medium text-gray-900 dark:text-gray-300"
          >Passing Score</label
        >
        <input
          v-model="quizStore.quiz.passingScore"
          type="range"
          min="0"
          max="100"
          id="passingScoreRange"
          class="w-full h-2 bg-gray-200 rounded-lg appearance-none cursor-pointer dark:bg-gray-700"
        />
      </div>
    </div>
  </div>
</template>
