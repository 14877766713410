<script setup>
import { ref, computed, onMounted } from "vue";
import UserDataService from "../services/UserDataService";
import UserTable from "../components/UserTable.vue";
const userList = ref([]);

function retrieveUserList() {
  UserDataService.getAll()
    .then((response) => {
      userList.value = response.data;
      console.log(response.data);
    })
    .catch((e) => {
      console.log(e);
    });
}
onMounted(() => {
  retrieveUserList();
});
</script>

<template>
  <UserTable :members="userList" />
</template>

<style scoped></style>
