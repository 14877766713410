<script setup>
import { ref, onMounted, computed } from "vue";
import { useBreadCrumbStore } from "../stores/breadCrumbs.js";
import { useRouter, useRoute } from "vue-router";
import {
  Disclosure,
  DisclosureButton,
  DisclosurePanel,
  Menu,
  MenuButton,
  MenuItem,
  MenuItems,
} from "@headlessui/vue";

import { BellIcon, Bars4Icon, XMarkIcon } from "@heroicons/vue/20/solid";
import { useUserStore } from "../stores/user";

const userStore = useUserStore();
const breadCrumbsStore = useBreadCrumbStore();

onMounted(() => {
  userStore.fetchUser();
  console.log("userStore.user", userStore.user);
  //send(`{ 'user': '${userStore.user.id}'}`);
});

const router = useRouter();
const route = useRoute();
const notifications = ref([]);

const handleSelect = (item) => {
  breadCrumbsStore.$patch({ breadcrumbs: [] });
  if (item.name !== "Home") {
    breadCrumbsStore.addCrumb({
      name: item.name,
      href: `/${item.href}`,
      current: false,
    });
  }
  if (item.basic) {
    router.push(`/${item.href}`);
  } else {
  }
};

const logOut = () => {
  window.location.href = "/api/users/logout";
};
const logIn = () => {
  router.push("/login");
};
</script>

<template>
  <div
    class="bg-gradient-to-b from-gray-900 to-black pb-32 font-header text-gray-100"
  >
    <Disclosure
      as="nav"
      class="sticky top-0 bg-black border-b-2 border-b-gray-900"
      v-slot="{ open }"
    >
      <div class="max-w-7xl mx-auto px-2 sm:px-4 lg:px-8">
        <div class="relative h-16 flex items-center justify-between">
          <div class="px-2 flex items-center lg:px-0">
            <div class="flex-shrink-0">
              <img
                class="block h-8 w-8"
                src="/images/logo_r.png"
                alt="Workflow"
              />
            </div>
            <div class="hidden lg:block lg:ml-10">
              <div class="flex space-x-4">
                <router-link
                  :class="[
                    'Home' === route.name
                      ? 'bg-gray-800 text-white'
                      : 'text-grey hover:bg-gray-700 hover:bg-opacity-75',
                    'rounded-sm py-2 px-3 text-sm font-medium',
                  ]"
                  to="/"
                  >Home</router-link
                >
                <a
                  v-for="item in userStore.user.topNav"
                  @click="handleSelect(item)"
                  :key="item.name"
                  :class="[
                    item.name === route.name
                      ? 'bg-gray-800 text-white'
                      : 'text-gray hover:bg-gray-700 hover:bg-opacity-75',
                    'rounded-sm py-2 px-3 text-sm font-medium',
                  ]"
                >
                  {{ item.name }}
                </a>

                <a
                  v-if="!userStore.user.name"
                  @click="logIn()"
                  class="text-gray hover:bg-indigo-500 hover:bg-opacity-75 rounded-md py-2 px-3 text-sm font-medium"
                >
                  Login
                </a>
              </div>
            </div>
          </div>

          <div class="flex lg:hidden">
            <!-- Mobile menu button -->
            <DisclosureButton
              class="bg-gray-200 p-2 rounded-md inline-flex items-center justify-center text-gray-800 hover:text-gray hover:bg-gray-300 hover:bg-opacity-75 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-offset-gray-600 focus:ring-white"
            >
              <span class="sr-only">Open main menu</span>
              <Bars4Icon
                v-if="!open"
                class="block h-6 w-6"
                aria-hidden="true"
              />
              <XMarkIcon v-else class="block h-6 w-6" aria-hidden="true" />
            </DisclosureButton>
          </div>
          <div v-if="userStore.user.name" class="hidden lg:block lg:ml-4">
            <div class="flex items-center">
              <button
                v-if="notifications.length > 0"
                type="button"
                class="inline-flex relative items-center p-2 text-sm font-medium text-center text-gray-100 bg-purple-700 rounded-full hover:bg-purple-800 focus:ring-4 focus:outline-none"
              >
                <BellIcon class="h-6 w-6" aria-hidden="true" />
                <span class="sr-only">Notifications</span>
                <div
                  class="inline-flex absolute -top-1 -right-1 justify-center items-center w-5 h-5 text-xs font-bold text-gray bg-violet-700 rounded-full border border-fuchsia-400"
                >
                  {{ notifications.length }}
                </div>
              </button>

              <!-- Profile dropdown -->
              <Menu as="div" class="ml-3 relative flex-shrink-0">
                <div>
                  <MenuButton
                    class="bg-gray-600 rounded-full flex text-sm text-gray-100 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-offset-indigo-600 focus:ring-white"
                  >
                    <span class="sr-only">Open user menu</span>
                    <img
                      class="rounded-full h-8 w-8"
                      :src="userStore.user.imageUrl"
                      alt="Profile"
                    />
                  </MenuButton>
                </div>
                <transition
                  enter-active-class="transition ease-out duration-100"
                  enter-from-class="transform opacity-0 scale-95"
                  enter-to-class="transform opacity-100 scale-100"
                  leave-active-class="transition ease-in duration-75"
                  leave-from-class="transform opacity-100 scale-100"
                  leave-to-class="transform opacity-0 scale-95"
                >
                  <MenuItems
                    class="origin-top-right absolute right-0 mt-2 w-48 rounded-md shadow-lg py-1 bg-white ring-1 ring-black ring-opacity-5 focus:outline-none"
                  >
                    <MenuItem
                      v-for="item in userStore.user.userNavigation"
                      :key="item.name"
                      v-slot="{ active }"
                    >
                      <a
                        :class="[
                          active ? 'bg-gray-100' : '',
                          'block py-2 px-4 text-sm text-gray-700',
                        ]"
                      >
                        {{ item.name }}
                      </a>
                    </MenuItem>
                    <MenuItem v-slot="{ active }">
                      <a
                        @click="logOut()"
                        :class="[
                          active ? 'bg-gray-100' : '',
                          'block py-2 px-4 text-sm text-gray-700',
                        ]"
                      >
                        Logout
                      </a>
                    </MenuItem>
                  </MenuItems>
                </transition>
              </Menu>
            </div>
          </div>
        </div>
      </div>

      <DisclosurePanel class="lg:hidden">
        <div class="px-2 pt-2 pb-3 space-y-1">
          <DisclosureButton
            as="a"
            href="/"
            :class="[
              'Home' === route.name
                ? 'bg-gray-700 text-white'
                : 'text-gray hover:bg-gray-400 hover:bg-opacity-75',
              'block rounded-md py-2 px-3 text-base font-medium',
            ]"
          >
            Home
          </DisclosureButton>
          <DisclosureButton
            v-for="item in userStore.user.topNav"
            @click="handleSelect(item)"
            :key="item.name"
            as="a"
            :class="[
              item.name === route.name
                ? 'bg-gray-700 text-white'
                : 'text-gray hover:bg-gray-400 hover:bg-opacity-75',
              'block rounded-md py-2 px-3 text-base font-medium',
            ]"
          >
            {{ item.name }}
          </DisclosureButton>
        </div>
        <div class="pt-4 pb-3 border-t text-gray-900">
          <div class="px-5 flex items-center">
            <div class="flex-shrink-0 bg-gray-600 rounded-full">
              <img
                class="rounded-full h-10 w-10"
                :src="userStore.user.imageUrl"
                alt=""
              />
            </div>
            <div class="ml-3">
              <div class="text-base font-medium text-gray-100">
                {{ userStore.user.name }}
              </div>
              <div class="text-sm font-medium text-gray-400">
                {{ userStore.user.email }}
              </div>
            </div>
            <button
              v-if="notifications.length > 0"
              type="button"
              class="ml-auto bg-indigo-600 flex-shrink-0 rounded-full p-1 text-gray-900 hover:text-gray focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-offset-indigo-600 focus:ring-white"
            >
              <span class="sr-only">View notifications</span>
              <BellIcon class="h-6 w-6" aria-hidden="true" />
            </button>
          </div>
          <div class="mt-3 px-2 space-y-1">
            <DisclosureButton
              v-for="item in userStore.user.userNavigation"
              :key="item.name"
              as="a"
              class="block rounded-md py-2 px-3 text-base font-medium text-gray hover:bg-indigo-500 hover:bg-opacity-75"
            >
              {{ item.name }}
            </DisclosureButton>
          </div>
        </div>
      </DisclosurePanel>
    </Disclosure>
    <header class="py-4"></header>
  </div>
</template>

<style>
.flex-grow {
  flex-grow: 1;
}
</style>
