// stores/quizStats.js
import { defineStore } from "pinia";
import axios from "axios";

export const useQuizStore = defineStore("quiz", {
  state: () => ({
    quiz: {},
  }),
  getters: {
    getQuiz(state) {
      return state.quiz;
    },
  },
  actions: {
    async deleteQuiz() {
      const response = await axios.delete(
        "/api/courses/quiz/" + this.quiz.course
      );
      return response;
    },
    async postQuiz() {
      const response = await axios.post("/api/courses/quiz", this.quiz);
      return response;
    },
    async fetchQuiz(id) {
      try {
        const data = await axios.get("/api/courses/quiz/" + id);
        this.quiz = data.data;
      } catch (error) {
        this.quiz = { success: "error" };
      }
    },
  },
});
