<script setup></script>

<template>
  <div class="max-w-7xl mx-auto py-1 px-1 sm:px-6 lg:px-2">
    <div
      class="bg-gradient-to-r from-gray-700/50 to-gray-900 rounded-lg shadow-xl overflow-hidden lg:grid lg:grid-cols-2 lg:gap-4"
    >
      <div
        class="pt-10 pb-12 px-6 sm:pt-16 sm:px-16 lg:py-16 lg:pr-0 xl:py-20 xl:px-20"
      >
        <div class="lg:self-center">
          <h2
            class="text-3xl tracking-tight font-bold text-transparent bg-clip-text bg-gradient-to-r from-indigo-500 via-purple-500 to-pink-500 sm:text-4xl sm:tracking-tight"
          >
            <span class="block"
              >Easily convert your PowerPoint into an HTML5 SCORM package ready
              for your Learning Management System.</span
            >
          </h2>
          <p class="mt-4 text-lg leading-6 text-pink-200">
            Simply join below, upload your PowerPoint, and then download your
            SCORM Package.
          </p>
          <a
            href="/login"
            class="mt-8 bg-white border border-transparent rounded-md shadow px-5 py-3 inline-flex items-center text-base font-medium text-pink-600 hover:bg-purple-50"
            >Sign up for free</a
          >
        </div>
      </div>
      <div class="-mt-6 aspect-w-5 aspect-h-3 md:aspect-w-2 md:aspect-h-1">
        <img
          class="transform translate-x-6 translate-y-6 rounded-md object-cover object-left-top sm:translate-x-6 lg:translate-y-20"
          src="/images/scormr_upload.gif"
          alt="App screenshot"
        />
      </div>
    </div>
    <div class="pt-4 mt-4">
      <div class="grid justify-items-center">
        <h2
          class="tracking-tight font-bold text-gray-100 sm:text-4xl sm:tracking-tight"
        >
          How would you like to create eLearning?
        </h2>
      </div>
      <div class="justify-center flex flex-wrap gap-3 pt-3">
        <div class="card bg-base-300 shadow-xl">
          <div class="card-body">
            <h2 class="card-title">The old expensive way:</h2>
            <ul class="steps steps-vertical">
              <li class="step step-neutral">
                Create a storyboard in PowerPoint
              </li>
              <li class="step step-neutral">Look for an eLearning developer</li>
              <li class="step step-neutral">
                Negotiate a price ($3,000 - $30,000)
              </li>
              <li class="step step-neutral">Send them your PowerPoint</li>
              <li class="step step-neutral">Wait weeks or months</li>
              <li class="step step-neutral">Review their work</li>
              <li class="step step-neutral">Send feedback</li>
              <li class="step step-neutral">Wait days or weeks</li>
              <li class="step step-neutral">Get a SCORM package from them</li>
              <li class="step step-neutral">Upload it to your LMS</li>
            </ul>
          </div>
        </div>
        <div class="card h-fit bg-base-100 shadow-xl">
          <div class="card-body">
            <h2 class="card-title">
              With
              <span
                class="tracking-tight font-bold text-transparent bg-clip-text bg-gradient-to-r from-indigo-500 via-purple-500 to-pink-500 sm:text-4xl sm:tracking-tight"
                >SCORMR</span
              >:
            </h2>
            <ul class="steps steps-vertical">
              <li class="step step-primary">
                Create a storyboard in PowerPoint
              </li>
              <li class="step step-primary">Upload it to SCORMR (FREE*)</li>
              <li class="step step-primary">Download your SCORM package</li>
              <li class="step step-primary">Upload it to your LMS</li>
            </ul>
          </div>
          <span class="text-sm text-gray-500 text-right pr-5 pb-2"
            >*Free while in beta</span
          >
        </div>
      </div>
    </div>
  </div>
</template>

<style scoped></style>
