<script setup>
import { useScriptTag } from "@vueuse/core";
import axios from "axios";
useScriptTag(
  "https://www.gstatic.com/firebasejs/9.1.3/firebase-app-compat.js",
  (el) => {}
);
useScriptTag(
  "https://www.gstatic.com/firebasejs/9.1.3/firebase-auth-compat.js",
  (el) => {}
);
useScriptTag(
  "https://www.gstatic.com/firebasejs/ui/6.0.1/firebase-ui-auth.js",
  // on script tag loaded.
  (el) => {
    const firebaseConfig = {
      apiKey: "AIzaSyAzzdojFmvH-LuZWnmibvODW1JCPIZNCTg",
      authDomain: "scormer-test.firebaseapp.com",
      projectId: "scormer-test",
      storageBucket: "scormer-test.appspot.com",
      messagingSenderId: "39314689903",
      appId: "1:39314689903:web:abb1bb5139b9fc4739e29a",
      measurementId: "G-F3CBED30MV",
    };

    firebase.initializeApp(firebaseConfig);
    firebase.auth().setPersistence(firebase.auth.Auth.Persistence.NONE);
    // FirebaseUI config.
    var uiConfig = {
      signInOptions: [
        // Leave the lines as is for the providers you want to offer your users.
        firebase.auth.GoogleAuthProvider.PROVIDER_ID,
        firebase.auth.EmailAuthProvider.PROVIDER_ID,
      ],
      // tosUrl and privacyPolicyUrl accept either url string or a callback
      // function.
      // Terms of service url/callback.
      tosUrl: "<your-tos-url>",
      // Privacy policy url/callback.
      privacyPolicyUrl: function () {
        window.location.assign("<your-privacy-policy-url>");
      },
      callbacks: {
        signInSuccessWithAuthResult: function (authResult, redirectUrl) {
          // User successfully signed in.
          authResult.user
            .getIdToken()
            .then(function (idToken) {
              if (authResult.additionalUserInfo.isNewUser) {
                axios.post("/api/users", { user: authResult.user });
                window.gtag("event", "sign_up", {
                  method: "website",
                });
                window.posthog.capture("newUser");
              }
              window.gtag("event", "login", {
                method: "website",
              });
              window.posthog.identify(authResult.user.uid);
              window.posthog.people.set({ email: authResult.user.email });
              window.location.href = "/api/users/savecookie?idToken=" + idToken;
            })
            .catch((error) => {
              console.log(error);
            });
          return false;
        },
      },
    };

    // Initialize the FirebaseUI Widget using Firebase.
    var ui = new firebaseui.auth.AuthUI(firebase.auth());
    // The start method will wait until the DOM is loaded.
    ui.start("#firebaseui-auth-container", uiConfig);
    /**/
  }
);
</script>

<template>
  <link
    type="text/css"
    rel="stylesheet"
    href="https://www.gstatic.com/firebasejs/ui/6.0.1/firebase-ui-auth.css"
  />
  <div id="firebaseui-auth-container"></div>
</template>

<style scoped></style>
